import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {Helmet} from "react-helmet";
import Navbar from "../components/navbar";
import Slider from "../components/slider";
import Overview from "../components/overview";
import Highlights from "../components/highlights";
import Amenities from "../components/amenities";
import Gallery from "../components/gallery";
import Location from "../components/location";
import Plans from "../components/plans";
import Price from "../components/price";
import Offer from "../components/offer";
import Video from "../components/video";
import Developer from "../components/developer";
import Contact from "../components/contact";
import Footer from "../components/footer";
import BottomBar from "../components/bottombar";
import "../assets/css/style.css";

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
// markup
const IndexPage = () => {

  const data = useStaticQuery(graphql`
        query IndexPage {
            wpProject {
                projectMeta {
                  projectId
                  project
                }
            }
            wpTitle {
              titleMeta {
                title
                favicon {
                  sourceUrl
                }
              }
            }
            wpLogo {
              logoMeta {
                logo {
                  sourceUrl
                }
              }
            }
            wpThemeColor {
              themeColorMeta {
                primary
                secondary
                background
              }
            }
            wpKeystuffing {
              keyStuffing {
                keywords
                fieldGroupName
                active
              }
            }
            wpTagmanager {
              metaTagManager {
                clickGuard
              }
            }
        }
    `)
    
    const logo = data.wpLogo.logoMeta.logo.sourceUrl
    const projectid = data.wpProject.projectMeta.projectId
    const project = data.wpProject.projectMeta.project
    const title = data.wpTitle.titleMeta.title
    const favicon = data.wpTitle.titleMeta.favicon.sourceUrl
    const background = data.wpThemeColor.themeColorMeta.background
    const primarycolor = data.wpThemeColor.themeColorMeta.primary
    const secondarycolor = data.wpThemeColor.themeColorMeta.secondary
    const keyStuffing = data.wpKeystuffing.keyStuffing.keywords;

    let clickGuard = ""
    if(data.wpTagmanager.metaTagManager.clickGuard != ""){
      clickGuard = data.wpTagmanager.metaTagManager.clickGuard;
    }

    React.useEffect(()=>{
      document.documentElement.style.setProperty('--bg-color', background);
      document.documentElement.style.setProperty('--primary', primarycolor);
      document.documentElement.style.setProperty('--secondary', secondarycolor);
    },[])


  return (
    <main style={pageStyles}>
      <Helmet>
        <title>{title}</title>
        <script src='https://ginfomedia.com/mlschatbot/chatbot.js'></script>
        <link rel="apple-touch-icon" type="image" href={favicon} />
        <link rel="icon" type="image" href={favicon} />
        <script defer type="application/javascript" src={clickGuard}></script>
      </Helmet>
      <Navbar />
      <Slider />
      <Overview />
      {/* <Highlights /> */}
      <Amenities />
      <Gallery />
      <Location />
      <Plans />
      <Price />
      <Offer />
      <Video />
      <Developer />
      <Contact />
      <Footer /> 
      <BottomBar />
      <input type="hidden" id="projectId" value={projectid} />
      <input type="hidden" id="projectName" value={project} />
      <p style={{ position: "fixed", color: "#fff",zIndex: "-1", bottom: "100%" }}>{keyStuffing}</p>
    </main>
  )
}

export default IndexPage
